<template>
  <div>
    <v-container fluid>
      <v-card flat>
        <v-card-title>
          <span v-if="!isEdit">
            {{ $t("anshaa-ktab") }} {{ type.single }}
          </span>
          <span v-else>
            {{ $t("edit") }}
            <span class="font-weight-bold">
              {{ item.title }}
            </span>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <v-form v-model="valid" @submit.prevent="add">
            <v-row>
              <v-col cols="12" md="7">
                <v-row>
                  <v-col cols="12" md="12">
                    <h3 class="mb-2">
                      {{ $t("alanwan") }}
                    </h3>
                    <v-text-field
                      v-model="item.title"
                      :placeholder="$t('alanwan')"
                      :rules="[]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("rqm-alktab") }}
                    </h3>
                    <v-text-field
                      v-model="item.number"
                      :placeholder="$t('rqm-alktab')"
                      :rules="[]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("tarykh") }} {{ $t("alsadr") }}

                      <datefield
                        v-model="item.date"
                        :oldData="item.date"
                        :rules="[]"
                      />
                    </h3>
                  </v-col>
                  <v-col v-show="type.type == 2" cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("add-alward") }}
                    </h3>
                    <v-text-field
                      v-model="item.sec_number"
                      :placeholder="$t('alrqm')"
                      :rules="[]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" v-show="type.type == 2" md="6">
                    <h3 class="mb-2" v-if="type.type == 2">
                      {{ $t("tarykh") }}
                      {{ $t("alward") }}
                    </h3>
                    <datefield
                      v-model="item.sec_date"
                      :oldData="item.sec_date"
                      :rules="[]"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">{{ $t("alahmyh") }}</h3>
                    <v-select
                      v-model="item.priority"
                      item-text="text"
                      :items="priorities"
                      item-value="id"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("alnwa") }}
                    </h3>
                    <v-select
                      v-model="item.documentCategoryId"
                      item-text="name"
                      :items="$global.state.documentCategory"
                      item-value="id"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3 class="mb-2">{{ $t("alanawyn-alfrayh") }}:</h3>
                    <v-combobox
                      filled
                      multiple
                      outlined
                      hide-details="auto"
                      small-chips
                      hint="اضغط Enter بعد كتابة العنوان"
                      v-model="tags"
                    >
                    </v-combobox>
                  </v-col>

                  <v-col cols="12" md="6">
                    <h3 class="mb-2">
                      {{ $t("aljhh") }}
                    </h3>
                    <v-autocomplete
                      v-model="item.ministryId"
                      :items="$global.state.ministries"
                      item-text="name"
                      item-value="id"
                      hide-details="auto"
                      outlined
                      filled
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12">
                    <h3 class="mb-2">{{ $t("mlahzat") }}</h3>
                    <VueEditor dir="ltr" v-model="item.note" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="5">
                <multiple-files
                  :returnArray="true"
                  v-model="files"
                  :field="field"
                  :existing-data="existingData"
                  :key="existingData"
                />
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12">
                <div class="d-flex justify-end">
                  <v-btn
                    type="submit"
                    :loading="this.$global.state.loading"
                    :disabled="!valid || this.$global.state.loading"
                    color="primary"
                    x-large
                  >
                    <v-icon left> save </v-icon>
                    <h3>{{ $t("save") }}</h3>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container> 
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import datefield from "../../components/fields/datefield.vue";
import MultipleFiles from "../../components/fields/MultipleFiles.vue";
export default {
  components: {
    VueEditor,
    datefield,
    MultipleFiles,
  },

  computed: {
    type() {
      let obj = {};
      if (!this.isEdit) {
        switch (this.$route.params.type) {
          case "outgoing":
            obj = {
              type: 1,
              title: this.$t("alsadr"),
              single: this.$t("alsadr"),
            };
            break;
          case "incoming":
            obj = {
              type: 2,
              title: this.$t("alward"),
              single: this.$t("alward"),
            };
            break;
          case "internal":
            obj = {
              type: 3,
              title: this.$t("mthkrat-dakhlyh"),
              single: this.$t("mthkrat-dakhlyh"),
            };
            break;
        }
      } else {
        switch (this.item.type) {
          case 1:
            obj = {
              type: 1,
              title: this.$t("alsadr"),
              single: this.$t("alsadr"),
            };
            break;
          case 2:
            obj = {
              type: 2,
              title: this.$t("alward"),
              single: this.$t("alward"),
            };
            break;
          case 3:
            obj = {
              type: 3,
              title: this.$t("mthkrat-dakhlyh"),
              single: this.$t("mthkrat-dakhlyh"),
            };
            break;
        }
      }

      return obj;
    },
  },
  data() {
    return {
      tags: [],
      valid: false,
      isEdit: false,
      field: {
        field: {
          title: "المرفقات",
        },
      },
      item: {
        title: "",
        number: "",
        date: "",
        sec_number: "",
        ministryId: null,
        sec_date: null,
        type: 0,
        note: "",
        tags: "",
        documentCategoryId: null,
        priority: 2,
        category: "",
        userId: this.$store.state.user.id,
        attachments: [],
      },
      priorities: [
        { text: "انتباه", id: 0 },
        { text: "عاجل", id: 1 },
        { text: "عادي", id: 2 },
        { text: "سري", id: 3 },
      ],
      types: [
        { text: "اعمام", id: "1" },
        { text: "مخصصات", id: "2" },
        { text: "امر وزاري", id: "3" },
        { text: "امر اداري", id: "4" },
        { text: "شكر وتقدير", id: "5" },
      ],
      files: [],
      existingData:''
    };
  },
  async created() {
    this.$global.dispatch("getMinistry", {});

    this.$global.dispatch("getDocumentCategory", {});
    if (this.$route.params.id) {
      let res = await this.$http.get("/Document", {
        params: { id: this.$route.params.id },
      });
      this.item = res.data.result[0];
      console.log(res.data.result[0]);
      this.files = []
      for (let i = 0; i < this.item.attachments.length; i++) {
        console.log('weweweweweew')
        console.log(this.item.attachments[i].path)
        this.files.push(this.item.attachments[i].path)
        console.log(this.files[i])
      }
      this.existingData = this.files.join().toString()
      console.log(this.existingData)
      console.log(this.files)

      this.tags = this.item.tags.split(",");
      if (this.item.ministry) {
        this.item.ministryId = this.item.ministry.id;
      } else {
        this.item.ministryId = null;
      }
      if (this.item.documentCategory) {
        this.item.documentCategoryId = this.item.documentCategory.id;
      } else {
        this.item.documentCategoryId = null;
      }

      this.isEdit = true;
    } else {
      this.$http.get("/document/count", {
          params: {
            type: this.$route.params.type == "outgoing" ? 0 : 1,
          },
        }).then((res) => {
          if (this.$route.params.type == "outgoing") {
            this.item.number = res.data.result.count.toString();
          } else {
            this.item.sec_number = res.data.result.count.toString();
          }
        });
    }
  },
  methods: {
    async add() {
      this.$global.state.loading = true;

      this.item.type = this.type.type;
      try {
        this.item.tags = this.tags.join(",");
        this.item.userId = this.$store.state.user.user.id;
        
        if(this.files!= undefined){
          this.files.forEach((file) => {
          this.item.attachments.push({
              path: file,
              documentId: null,
            });
          });
        }
        var data = this.item
        if (data.date == "Invalid date"){
          data.date = null
        }
        if (this.isEdit) {
          var data2 = data
          data2.documentAttachments = data.attachments
          await this.$http.put("/document/" + this.$route.params.id, data2);

          this.$router.push("/document/" + this.$route.params.id);
        } else {
          await this.$http.post("/document", data);
          this.$router.push("/documentsList/" + this.$route.params.type);
        }
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    onFileChanged(e) {
      this.handleFileUpload({
        file: e.target.files[0],
        endPoint: "UploadFile",
      });
    },
    onButtonClick() {
      window.addEventListener("focus", () => {}, {
        once: true,
      });

      this.$refs[`uploader`].click();
    },
    async handleFileUpload({ file, endPoint }) {
      this.$global.state.uploading = true;
      try {
        let formData = new FormData();
        formData.append("files", file);
        let res = await this.$http.post(endPoint, formData);

        this.$global.state.path = res.data.fileList[0].path;

        this.files.push(res.data.fileList[0]);
        this.$service.success("تم رفع الملف بنجاح");
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.uploading = false;
      }
    },
  },
};
</script>
